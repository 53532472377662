@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Toast styles */
.Toastify__toast {
  scale: 0.75;
  translate: 10% -20%;
}

/* Set default font to Poppins */
@layer base {
  html {
    font-family: 'Poppins', system-ui, sans-serif;
  }
}

/* Bss app transition */
.intro-transition {
  transition:
    width 1s linear,
    height 1s linear;
}

/* Chatbot styles */
/* .react-chatbot-kit-chat-container {
    @apply relative w-full;
}
*/
.react-chatbot-kit-chat-inner-container {
  @apply h-[50vh] pb-12;
}

.react-chatbot-kit-chat-message-container {
  @apply px-4 overflow-y-auto h-full flex flex-col relative text-sm;
}

.react-chatbot-kit-chat-message-container > :first-child {
  margin-top: auto;
}

/* .react-chatbot-kit-chat-input-container {
    @apply absolute bottom-0 right-0 left-0 w-full mx-4 border-gray-400 border-2 rounded-full;
} */

.react-chatbot-kit-chat-input-form {
  @apply w-5/6 absolute bottom-0 p-2 mx-auto mb-2 border-gray-400 border-2 rounded-full flex justify-between items-center bg-white;
  margin-left: 8%;
}

.react-chatbot-kit-chat-input {
  @apply w-full text-sm m-0 p-0 outline-none;
}

.react-chatbot-kit-chat-btn-send,
.react-chatbot-kit-chat-btn-upload {
  @apply bg-white mr-2;
}

.react-chatbot-kit-chat-btn-send-icon,
.react-chatbot-kit-chat-btn-upload-icon {
  @apply fill-current w-6 mx-auto bg-white text-gray-400;
}

/* Bullet Point Slide transition */
@keyframes slide {
  from {
    transform: translateX(150%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animationOne {
  0% {
    transform: none;
  }
  to {
    transform: translateY(10%);
  }
}
@keyframes animationTwo {
  0% {
    transform: none;
  }
  to {
    transform: translateY(-10%);
  }
}
@keyframes animationThree {
  0% {
    transform: none;
  }
  to {
    transform: translateX(15%);
  }
}
@keyframes animationFour {
  0% {
    transform: none;
  }
  to {
    transform: translateX(-15%);
  }
}

.animate-one {
  animation: animationOne 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animate-two {
  animation: animationTwo 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animate-three {
  animation: animationThree 3.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.animate-four {
  animation: animationFour 3.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite alternate;
}

.central-align {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%); /* This is a shorthand of translateX(-50%) and translateY(-50%) */
}

/* Focus selected animation */
@keyframes focus-selected {
  0%,
  50% {
    @apply bg-inherit;
  }
  100% {
    @apply bg-slate-400;
  }
}

.select-item {
  animation: focus-selected 3s linear forwards;
}

@keyframes show-circle {
  0% {
    @apply border-2 h-10 w-10;
    transform: translate(0, 0);
  }
  50% {
    @apply border-4 h-10 w-10;
    transform: translate(-2rem, -2rem);
  }
  75% {
    @apply border-8 h-6 w-6;
    transform: translate(-2rem, -2rem);
  }
  100% {
    @apply border-2 h-12 w-12;
    transform: translate(-2rem, -2rem);
  }
}

.circle-animation {
  animation: show-circle 3s linear forwards;
}

.full-screen-avatar {
  @apply h-full w-full;
}

.side-screen-avatar {
  @apply h-[calc(50vh-3rem)]  w-[33vw];
}

/* Code gif */
.scroll-text {
  position: absolute;
  white-space: pre-wrap;
  animation: scroll-up 14s linear forwards; /* Adjust time as required */
}

@keyframes scroll-up {
  100% {
    transform: translateY(calc(-100% + 85vh));
  }
}

@keyframes scroll-up-2 {
  100% {
    transform: translateY(calc(-100% + 12rem));
  }
}

@keyframes scroll-up-3 {
  100% {
    transform: translateY(calc(-100% + 10rem));
  }
}

.translate-text-2 {
  white-space: pre-wrap;
  transform: translateY(calc(-100% + 12rem));
}

.scroll-text-2 {
  white-space: pre-wrap;
  animation: scroll-up-2 95s linear forwards; /* Adjust time as required */
}

.scroll-text-3 {
  white-space: pre-wrap;
  animation: scroll-up-3 90s linear forwards; /* Adjust time as required */
}

@keyframes scroll-up-2 {
  /* 0% {
    top: 0%;
  } */
  100% {
    transform: translateY(calc(-100% + 15vh));
  }
}

.scroll-text-2 {
  white-space: pre-wrap;
  animation: scroll-up-2 15s linear forwards; /* Adjust time as required */
}

.selected-bird-map {
  @apply border-4;
}

.add-backdrop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  background: black;
}

.add-backdrop * {
  z-index: 10;
  position: relative;
  background-color: white;
}

/* Remove double cursor on input fields */
input {
  @apply cursor-none hover:cursor-none;
}
